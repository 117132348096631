html, body, #root {
  height: 100%;
}

a, a:visited, a:active {
  color: #45B3E2;
  text-decoration: none;
}

a:hover {
  color: hsla(198, 73%, 43%, 1);
}

.app {
  height: 100%;
  box-sizing: border-box;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  color: #19191A;
}

.notice, .not-found {
  text-align: center;
}

.notice, .not-found .email {
  font-style: italic;
  font-size: 0.8em;
  color: grey;
}

.not-found .copy, .loading {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7em;
  color: #4e4e4e;
}

.person {
  margin: 0px 4px;
  padding: 12px;
  border: 1px solid #E8EAED;
  border-radius: 16px;
}

.person-company {
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.15px;
}

.person-name {
  line-height: 24px;
  font-size: 19px;
  font-weight: bold;
}

.person-email, .person-title, .person-role {
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.person-email .email-link {
  cursor: pointer;
  color: #9B9C9E;
}

.person-email .email-link:hover {
  color: #4e4e4e;
}

.separator {
  width: 95%;
  margin: 10px auto;
  border-top: 1px solid lightgrey;
}

.account-details {
  position: relative;
  margin-top: 27px;
  margin-left: 14px;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
}

.edit-mode {
  border-radius: 16px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-image: url('./images/pen-icon.svg');
}

.edit-mode.active, .edit-mode:hover {
  background-color: #EDEDED;
}

.attributes {
  margin-top: 10px;
}

.attributes-table {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 0px 7px;
}

.attributes-table li {
  display: flex;
  border-bottom: 1px solid #F3F4F6;
}

.attributes-table li .attribute {
  display: flex;
  width: 100%;
  padding: 7px;
  margin: 2px 0px;
  border-radius: 8px;
}

.attributes-table li .attribute-label, .attributes-table li .attribute-value {
  width: 50%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  letter-spacing: -0.15px;
}

.attributes-table li .attribute > div:nth-child(1) {
  color: #19191A;
}

.attributes-table li .attribute > div:nth-child(2) {
  color: #9B9C9E;
  text-align: right;
}

.attributes-table li.editing {
  border-bottom: 1px solid transparent;
}

.attributes-table li.editing .attribute {
  background-color: #f5f5f5;
}

.add-field.editing {
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  letter-spacing: -0.15px;
}

.add-field ul {
  padding: 0;
  width: 100%;
}

.add-field ul li {
  border: 0;
  padding: 4px 0px;
}

.attributes-table .attribute-remove {
  display: none;
  position: absolute;
  margin-top: 2px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  right: 18px;
  background-image: url('./images/close-icon.svg');
}

.attributes-table li.editing:hover .attribute-remove {
  display: block;
}

.healthscore {
  min-width: 20px;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 2px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
}

.healthscore.green {
  border: 1px solid hsl(100, 60%, 50%);
  background-color: hsl(100, 70%, 50%);
}

.healthscore.yellow {
  border: 1px solid hsl(45, 60%, 50%);
  background-color: hsl(45, 70%, 50%);
}

.healthscore.red {
  border: 1px solid hsl(0, 60%, 50%);
  background-color: hsl(0, 70%, 50%);
}

.revenue {
  font-weight: bold;
}

form {
  margin: 0 auto;
  width: 95%;
}

form label {
  display: block;
  margin-bottom: 10px;
}

form .label {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  font-weight: bold;
}

form input[type=text], form input[type=number], form textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
  line-height: 20px;
  padding: 4px 8px;
  outline: 0px;
}

form input[type=text]:focus, form input[type=number]:focus, form textarea:focus {
  border: 1px solid #367fee;
  background: white;
}

form input[type=submit] {
  color: white;
  background-color: rgb(54, 127, 238);
  padding: 6px 12px 7px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 17px;
}

form input[type=submit]:hover {
  background-color: rgb(34, 86, 179);
}

form .close-button {
  margin-left: 10px;
  font-size: 14px;
}

form .close-button a {
  color: #9B9C9E;
}

.credentials-logo {
  position: relative;
}

.settings-gear {
  border-radius: 16px;
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 25px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-image: url('./images/gear-icon.svg');
}

.settings-gear:hover {
  background-color: #ededed;
}

.settings-menu {
  padding: 0px 13px;
  list-style: none;
  position: absolute;
  right: 7px;
  background: white;
  border: 1px solid #E8EAED;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.1);
}

.settings-menu li {
  margin: 12px 0px;
  font-size: 14px;
  line-height: 17px;
}

.settings-menu li a {
  display: inline-block;
  width: 100%;
}

table.fields-table {
  table-layout: fixed;
  width: 100%;
  font-size: 10px;
}

table.fields-table thead {
  font-weight: bold;
}

table.fields-table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

table.fields-table tr td:nth-child(3) {
  width: 25%;
}

table.fields-table tr td:nth-child(4) {
  width: 12px;
}

table.fields-table tr input {
  font-size: 10px;
  height: 18px;
  border-radius: 2px;
  padding: 0px 2px;
}

table.fields-table tr select {
  font-size: 10px;
  border-color: lightgrey;
  border-radius: 2px;
  padding: 2px 0px;
}

.plus-button, .cross-button {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  color: white;
  background-color: #45B3E2;
  display: block;
  text-align: center;
  line-height: 10px;
  cursor: pointer;
}

.cross-button {
  background-color: #a1a4a6;
  transform: rotate(-45deg);
}

/* Themes */
.gainsight .credentials-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px auto;
  background-image: url('./images/gainsight-logo.png');
  width: 100%;
  margin: 0 auto;
  padding: 35px 0px;
}

.totango .credentials-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('./images/totango-logo.png');
  max-width: 80%;
  height: 100px;
  margin: 0 auto;
}